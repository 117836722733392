<template>
   <v-container>
      <v-row class="top-nav">
         <v-col cols="5" class="title label-color">
            <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile" @click.stop="$store.commit('toggleDrawer')"></v-app-bar-nav-icon>
            List User
         </v-col>
         <v-spacer />
         <v-col cols="1">
            <v-btn icon color="green" @click="uid='new'">
               <v-icon>mdi-plus</v-icon>
            </v-btn>
         </v-col>
         <v-col cols="5">
            <v-text-field dense label="Cari user" hide-details v-model="search"/>
         </v-col>
         <v-col cols="1">
            <v-btn icon color="#4b8" @click="show_filter=!show_filter" title="filter">
               <v-icon>mdi-filter</v-icon>
            </v-btn>
         </v-col>
      </v-row>
      <v-expand-transition>
         <v-row class="top-nav" v-show="show_filter">
            <v-col cols="12" sm="4">
               <v-select hide-details multiple label="Group" v-model="filter.group" :items="all_grp" @change="getData"/>
            </v-col>
            <v-col cols="12" sm="4">
               <v-select hide-details multiple label="Branch" v-model="filter.branch" :items="all_cabang" @change="getData"/>
            </v-col>
            <v-col cols="12" sm="4">
               <v-select hide-details clearable label="Status" v-model="filter.is_disabled" :items="status_opt" @change="getData"/>
            </v-col>
         </v-row>
      </v-expand-transition>
      <v-data-table
         :headers="header"
         :items="items"
         :server-items-length="total"
         item-key="id"
         :page.sync="page"
         :loading="loading"
         :footer-props="footer"
         :items-per-page="limit"
         :sort-by.sync="sort_by"
         :sort-desc.sync="sort_desc"
      >
         <template v-slot:[`item.act`]="{ item }">
            <v-btn class="hidden-sm-and-down" outlined fab x-small color="primary" @click="uid=item.id" title="edit"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn class="hidden-sm-and-down mx-1" outlined fab x-small color="indigo" @click="reset(item)" title="set password"><v-icon>mdi-key-change</v-icon></v-btn>
            <!-- <v-btn class="hidden-sm-and-down" outlined fab x-small color="red" @click="del(item)"><v-icon>mdi-delete</v-icon></v-btn> -->
            <v-btn class="hidden-md-and-up" icon color="blue" @click="more={...item}" title="pilihan"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
         </template>
      </v-data-table>
      <v-bottom-sheet :value="!!more.id" inset @click:outside="more={}">
         <v-sheet class="text-center" height="200px">
            <div class="headline py-4">{{more.nama}}</div>
            <v-divider class="mb-4 mx-3"/>
            <v-btn outlined color="primary" @click="uid=more.id"><v-icon>mdi-pencil</v-icon> edit</v-btn>
            <v-btn class="mx-2" outlined color="indigo" @click="reset(more)"><v-icon>mdi-key-change</v-icon> reset password</v-btn>
            <!-- <v-btn outlined color="red" @click="del(more)"><v-icon>mdi-delete</v-icon> hapus</v-btn> -->
         </v-sheet>
      </v-bottom-sheet>
      <profile :uid="uid" v-on:close="closeModal" />
   </v-container>
</template>
<script>
import {get, reset} from '../api/user';
import {get as get_model} from '../api/list';
import profile from '../components/profile';
let timer;

export default {
   components: { profile, },
   data: () => ({
      loading: false,
      show_filter: false,
      search: null,
      filter: {},
      items: [],
      footer: {
         'items-per-page-options': [15,30,50],
         showFirstLastPage: true,
         firstIcon: 'mdi-arrow-collapse-left',
         lastIcon: 'mdi-arrow-collapse-right',
         prevIcon: 'mdi-minus',
         nextIcon: 'mdi-plus'
      },
      page: 1,
      sort_by: 'id',
      sort_desc: true,
      limit: 15,
      total: 0,
      header: [
         { text: 'ID', value: 'id' },
         { text: 'Name', value: 'nama' },
         { text: 'User ID', value: 'user' },
         { text: 'Group', value: 'grp' },
         { text: 'Branch', value: 'branch' },
         { text: 'Status', value: 'status' },
         { text: 'Action', value: 'act', sortable: false }
      ],
      uid: null,
      more: {},
      all_cabang: [],
      all_grp: [],
      all_client: [],
      allowed_client: [],
      on_submit: false,
      status_opt: [{text:'Aktif',value:'0'},{text:'Non Aktif',value:'1'}]
   }),
   mounted() {
      this.getData();
      get_model('roles').then(rsl => { this.all_grp = rsl.map(x => ({text: x.grp, value: x.id})) });
      get_model('branch').then(rsl => { this.all_cabang = rsl.map(x => ({text: x.branch, value: x.id})) });
      get_model('client').then(rsl => { this.all_client = rsl; });//.map(x => ({text: x.branch, value: x.id}))
   },
   methods: {
      getData() {
         clearTimeout(timer);
         timer = setTimeout(() => {
            this.loading = true;
            const query = {start: ((this.page - 1) * this.limit), limit: this.limit};
            if(this.sort_by) query.sort = this.sort_by;
            if(this.sort_desc) query.desc = this.sort_desc;
            for(let i in this.filter) query[i]=this.filter[i];
            if(this.search) query.search = this.search;

            get(query).then(rsl => {
               this.items = rsl.data.map(x => ({...x, status: x.dis === 1 ? 'NON AKTIF':'AKTIF', grp: x.group1 + (x.group2 ? ' / '+ x.group2 : '')}));
               this.total = rsl.total;
            }).finally(() => { this.loading = false });
         }, 800);
      },
      closeModal(refresh) {
         if(refresh) this.getData();
         this.uid = null;
      },
      reset(usr) {
         this.$store.dispatch('alert', { title: 'Yakin akan mereset password?', text: `User: ${usr.nama}`, type: 'question', cancel: true, ok: () => {
            reset(usr.id).then(rsl => {
               if(rsl.stat != 1) this.$store.dispatch('alert', { title: 'Ooops!!!', text: rsl.mess, type: 'error' });
               else this.$store.dispatch('alert', { title: 'Success', text: `password baru: ${rsl.otp}`, type: 'success'});
            });
         } });
      },
      del(usr) {
         this.$store.dispatch('alert', { text: `Yakin akan menghapus akun ${usr.nama}?`, type: 'question', showCancelButton: true, ok: () => {
            // this.$api.crud.create(`user/${uid}/reset_password`).then(rsl => {
            //    if(rsl.error) this.$store.dispatch('alert', {title: 'Ooops!', text: rsl.error, type: 'error'});
            //    if(rsl.success) this.$store.dispatch('alert', { title: 'Success', text: rsl.success, type: 'success', timer: 2000, showConfirmButton: false});
            // });
         } });
      },
   },
   watch: {
      page() {
         this.getData();
      },
      limit() {
         this.getData();
      },
      sort_by() {
         this.getData();
      },
      sort_desc() {
         this.getData();
      },
      search() {
         this.getData();
      }
   },
}
</script>
